/** LOCAL */
/*
const backendServices = "http://192.168.1.181:8082/";
const keycloackService = "http://192.168.1.181:10000/auth/realms/talk-realm/protocol/openid-connect/token";
*/

/** VPN */
/*
const backendServices = "http://172.16.1.210:8082/";
const keycloackService = "http://172.16.1.210:10000/auth/realms/talk-realm/protocol/openid-connect/token";
*/

/** AWS SOUTH */

const backendServices = "https://api.test.oncotalk.it/";
const keycloackService = "https://auth.test.oncotalk.it/auth/realms/talk-realm/protocol/openid-connect/token";


/** PRODUCTION CNC */
/*
const backendServices = "http://ec2-54-86-10-141.compute-1.amazonaws.com:8082/";
const keycloackService = "http://ec2-54-147-83-51.compute-1.amazonaws.com:10000/auth/realms/talk-realm/protocol/openid-connect/token";
*/


export default {
  backendServices,
  "loginEndpoint": keycloackService,
  "categoriesEndpoint": backendServices+"talk/api/category/",
  "posts": backendServices+"talk/api/post/",
  "livePost": backendServices+"talk/api/post/live",
  "mediaLibrary": backendServices+"talk/api/media_library/",
  "media": backendServices+"talk/api/media",
  "headersEndpoint": backendServices+"talk/api/pageheader/",
  "sectionsEndpoint": backendServices+"talk/api/section/",
  "conferencesEndpoint": backendServices+"talk/api/conference/",
  "membersEndpoint": backendServices+"talk/api/staffmember/",
  "usersEndpoint": backendServices+"talk/api/user/all",
  "usersStatsEndpoint": backendServices+"talk/api/audit/",
  "verifyUserEndpoint": backendServices+"talk/api/user/state",
  "userEndpoint": backendServices+"talk/api/user/",
  "slidersEndpoint": backendServices+"talk/api/slider/",
  "sidebarEndpoint": backendServices+"talk/api/sidebar/",
  "streamingsEndpoint": backendServices+"talk/api/streaming/",
  "channelsEndpoint": backendServices+"talk/api/channel/",
  "postSponsorEndpoint": backendServices+"talk/api/post_sponsor/",
  "homePopupEndpoint": backendServices+"talk/api/homepopup/",
  "sponsorsEndpoint": backendServices+"talk/api/sponsor/",
  "specialsEndpoint": backendServices+"talk/api/special/"
}
